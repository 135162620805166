import React from "react";
// import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SearchTest from "../components/SearchTest";
import Cardboard from "../components/Cardboard";
import SEO from "../components/seo";

// console.log(hero);

export default ({ data }) => (
	<Layout>
		<SEO title="Dumbo Directory" keywords={[`dumbo`]} />
		<div className="px-6 relative z-10 md:py-6">
			<Cardboard>
				<SearchTest />
			</Cardboard>
		</div>
		<div className="container">
			<h1>About</h1>

			<div className="flex flex-wrap -mx-4">
				<div className="w-full md:w-1/3 mb-8 px-4">
					<h4>Our Office</h4>
				</div>
				<div className="w-full md:w-2/3 px-4 mb-8" />
			</div>
		</div>
	</Layout>
);
